import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import Container from '../components/Container';
import Layout from '../components/Layout';

const SecondPage = () => (
  <Layout>
    <Container
      css={css`
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <h1>Playground</h1>
      <p>Not so much to see here yet</p>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
);

export default SecondPage;
